<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form label-width="80px" @submit.native.prevent>
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest ?'(测试环境,数据定期删除)':''}}{{this.type == 'PZ' ? '当前：出纳单据一览（凭证专用）' : '当前：出纳单据一览'}}</strong>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :lg="24">
                        <el-row>
                            <el-col :sm="12" :md="2">
                                <el-row>
                                    <el-col class="pull-left" :lg="4">
                                        <vxe-toolbar class="pull-left" style=" height: 42px; " custom export ref="xToolbar2">
                                        </vxe-toolbar>
                                    </el-col>
                                </el-row>
                            </el-col>
                            <el-col :sm="24" :md="11">
                                <el-date-picker
                                        v-model="pickerMonthRange"
                                        style=" margin-top: 8px;padding-top: 2px; width: 200px"
                                        type="monthrange"
                                        align="right"
                                        size="mini"
                                        @change="$_pickerMonthRangeEvent()"
                                        unlink-panels
                                        :clearable="false"
                                        range-separator="至"
                                        start-placeholder="开始月份"
                                        end-placeholder="结束月份"
                                        :picker-options="pickerOptions">
                                </el-date-picker>
                                <el-date-picker
                                        style=" margin-top: 8px; width: 220px"
                                        v-model="pickerDate"
                                        type="daterange"
                                        size="mini"
                                        :clearable="false"
                                        :picker-options="pickerOptions"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        align="right">
                                </el-date-picker>
                                <el-tag type="danger" style="margin-left: 2px;">当前日期范围出纳单数量：{{allFinanceCount}}</el-tag>
                            </el-col>
                            <el-col :sm="12" :md="6" v-if="this.type != 'PZ'">
                                <el-form-item label="科目:">
                                    <el-select
                                            ref="roleSubject" v-model="formData.roleSubject"
                                            style="width: 100%"
                                            size="mini"
                                            clearable
                                            multiple
                                            collapse-tags
                                            remote
                                            :remote-method="getRoleSubjectByAccountId"
                                            filterable
                                            default-first-option
                                            placeholder="可选多选">
                                        <el-option
                                                v-for="item in roleSubjectList"
                                                :key="item.id_subject"
                                                :label="item.id_subject +'-'+item.subject_name"
                                                :value="item.subject_name">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="5" >
                                <el-button style=" margin-top: 8px; " type="primary" round @click="searchAllFinance" size="mini">搜索</el-button>
                                <el-button style=" margin-top: 8px; " type="primary" round :disabled="$refs.xGrid == null || $refs.xGrid.getCurrentRecord() == null"
                                           @click="openReceipt($refs.xGrid.getCurrentRecord())" size="mini">查看
                                </el-button>
                                <el-button type="primary" size="mini" @click="$router.back()" style="margin-bottom: 2px;margin-top: 2px">返回
                                </el-button>
                                <el-checkbox style="padding-left: 7px" v-model="formData.showCancel"
                                             @change="getAllFinance()">显示作废
                                </el-checkbox>
                            </el-col>
                        </el-row>
                    </el-col>

                </el-row>
                <el-row>
                    <el-col :lg="24">
                        <vxe-grid
                                border
                                resizable
                                show-overflow
                                keep-source
                                id="xGrid"
                                ref="xGrid"
                                :align="type=='PZ'?'right':'left'"
                                size="mini"
                                :height="(this.getViewHeight() - 130)+''"
                                highlight-current-row
                                :print-config="{}"
                                show-footer
                                :row-class-name="$_tableRowClassName"
                                :footer-method="footerMethod"
                                :cell-class-name="cellClassName"
                                @cell-dblclick="celldbClickEvent"
                                @cell-click="cellClickEvent"
                                :data="tableDataMain"
                                :columns="tableColumn"
                                :mouse-config="{selected: false}"
                                :custom-config="{storage:true}"
                                :edit-config="{showIcon:true,trigger: 'click', mode: 'cell', showStatus: true}"
                                :keyboard-config="{enterToTab:true,isArrow: true, isDel: false, isEnter: true, isTab: true,isEdit:false}">
                            <template #pager>
                                <vxe-pager
                                        :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
                                        :page-sizes="[50,500,1000,2500,50000]"
                                        :current-page.sync="tablePage.currentPage"
                                        :page-size.sync="tablePage.pageSize"
                                        :total="tablePage.total"
                                        @page-change="handlePageChange">
                                </vxe-pager>
                            </template>
                        </vxe-grid>
                    </el-col>

                </el-row>

            </el-form>
        </el-main>

    </div>

</template>

<script>
    import XEUtils from 'xe-utils'
    import {filterRender} from "@/utils/gird-filter";

    export default {

        data() {
            var now = new Date();
            var year = now.getFullYear();
            var month = now.getMonth() + 1;
            if (month < 10) {
                month = '0' + month;
            }
            // 本月第一天
            var startDate = new Date(year + '-' + month + '-01 00:00:00');
            return {
                //自定义表头
                /* headerRowStyle:{maxHeight:'80px',height:'80px'},
                   headerCellStyle:{maxHeight:'80px',height:'80px'},
                   showHeaderOverflow:false,*/
                //搜索筛选
                type:'',
                formData: {
                    isPZ:false,
                    roleSubject: [],
                    name: null,
                    showCancel: false,
               },
                roleSubjectList:[],//授权科目，可以多选
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
               },
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一年',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                       }
                   },  {
                        text: '本月',
                        onClick(picker) {

                            picker.$emit('pick', [startDate, now]);
                       }
                   },{
                        text: '上个月',
                        onClick(picker) {
                            var now = new Date();
                            var year = now.getFullYear();
                            var month = now.getMonth();
                            if(month==0){
                                month = 12;
                                year = year-1;
                           }
                            if(month<10){
                                month = '0'+month;
                           }
                            var myDate = new Date(year,month,0);
                            // 上个月第一天
                            var startDate = new Date(year+'-'+month+'-01 00:00:00');
                            // 上个月最后一天
                            var endDate = new Date(year+'-'+month+'-'+myDate.getDate()+' 23:59:00');
                            picker.$emit('pick', [startDate, endDate]);
                       }
                   }]
               },
                tableColumn: [
                    { field:'cash_type_id', width: 90 ,title: '出纳单号' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                       }
                   },
                    { field:'cash_type_name', width: 115,title: '单据类型(细分)' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                       }
                   },
                    { field:'cash_date', width: 95 ,title: '日期',formatter: ({cellValue}) => {return this.formatDate(cellValue,'yyyy-MM-dd')} ,slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                       }
                   },{ field:'id', visible: true,width: 65 ,title: '唯一id' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                       }
                   },
                   { field:'company_name', width: 250 ,title: '往来单位' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                       }
                   },
                   { field:'sales_man', width: 150 ,title: '业务员' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                        }
                   },
                   { field:'type', width: 110 ,title: '单据类型(英文大类)',visible: false, slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                       }
                   },{ field:'type_name', width: 115 ,title: '单位类型(大类)', slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                       }
                   },{ field:'subject_id_cash_data',visible : false,  width: 80 ,title: '科目编码' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                       }
                   },{ field:'subject_name_cash_data', width: 260 ,title: '借方科目名称' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                       }
                   },{ field:'credit_side_name',width: 260 ,title: '贷方科目名称' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                       }
                   },{ field:'summary_cash_data', width: 170 ,title: '摘要' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                        }
                    },{ field:'cash_data_remark',visible : true,  width: 150 ,title: '备注' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                       }
                   },
                    { field:'money_cash_data', visible : true, formatter: ({cellValue}) => {
                            /*if(cellValue > 0 ){
                                return cellValue
                           }else{
                                return  0 - cellValue
                           }*/
                            return cellValue
                       },width: 80 ,title: '金额' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                       }
                   },
                    { field:'total_balance_money', visible : true, formatter: ({cellValue}) => {
                            return 0 - cellValue
                        },width: 80 ,title: '冲账金额' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                       }
                   },
                    { field:'total_amount',visible : false,  formatter: ({cellValue}) => {
                            return cellValue
                       }, width: 90 ,title: '合计(金额)' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                       }
                   },
                    { field:'paid_up',visible : false,  formatter: ({cellValue}) => {
                            return cellValue
                       }, width: 90 ,title: '实收(金额)' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                       }
                   }
                    ,{ field:'hang_money',visible : false, width: 90 ,title: '挂数(金额)' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                       }
                   }
                    ,{ field:'registrar', width: 70 ,title: '记录员' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                       }
                   },{ field:'create_date', width: 170 ,title: '创建日期' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                       }
                   },{ field:'last_name_cash',visible : false, width: 90 ,title: '最后修改人' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                       }
                   },{ field:'last_date_cash', visible : false,width: 120 ,title: '修改日期' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                       }
                   },{ field:'re_check_man', visible : false,width: 60 ,title: '复核' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                       }
                   },{ field:'re_check_man_date',visible : false, width: 120 ,title: '复核日期' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                       }
                   },
                    { field:'check_man',visible : false, width: 70 ,title: '审核员' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                       }
                   },{ field:'check_man_date',visible : false, width: 120 ,title: '审核日期' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                       }
                   },{ field:'is_cancel_name', width: 60 ,title: '状态' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                       }
                   },{ field:'remark_cash', visible : false, width: 80 ,title: '单据备注' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                       }
                   },
                    { field:'bill_id_kd_or_jc', visible : true, width: 180 ,title: '销售、进仓（单号）' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                        }
                    },
                ],
                tableColumnPZ: [
                    { field:'cash_type_id', width: 90 ,title: '出纳单号' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                        }
                    },
                    { field:'voucher_type_id', width: 80 ,title: '凭证字' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                        }
                    },
                    { field:'v_registrar', width: 120 ,title: '生成凭证人' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                        }
                    },
                    { field:'cash_type', visible : false, width: 90 ,title: '单据类型' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                        }
                    },
                    { field:'type_name', width: 80 ,title: '单位类型', slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                        }
                    },
                    { field:'cash_date', width: 95 ,title: '日期',formatter: ({cellValue}) => {return this.formatDate(cellValue,'yyyy-MM-dd')} ,slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                        }
                    },{ field:'id', visible: true,width: 65 ,title: '唯一id' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                        }
                    },
                    { field:'company_name', width: 250 ,title: '往来单位' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                        }
                    },
                    { field:'type', width: 80 ,title: '单据类型',visible: false, slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                        }
                    },{ field:'subject_id_cash_data',visible : false,  width: 80 ,title: '科目编码' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                        }
                    },{ field:'subject_name_cash_data', width: 260 ,title: '科目名称' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                        }
                    },
                    { field:'money_borrow',width: 120 ,title: '借方金额' ,  visible : true,
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                        },
                        slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                        }
                    },{ field:'money_loan', visible : true, width: 120 ,title: '贷方金额' ,
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                        },
                        slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                        }
                    },
                    { field:'summary_cash_data', width: 170 ,title: '摘要' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                        }
                    },{ field:'cash_data_remark',visible : true,  width: 150 ,title: '备注' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                        }
                    },
                    { field:'total_balance_money', visible : false, formatter: ({cellValue}) => {
                            return 0 - cellValue
                        },width: 80 ,title: '冲账金额' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                        }
                    },
                    { field:'total_amount',visible : false,  formatter: ({cellValue}) => {
                            return cellValue
                        }, width: 90 ,title: '合计(金额)' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                        }
                    },
                    { field:'paid_up',visible : false,  formatter: ({cellValue}) => {
                            return cellValue
                        }, width: 90 ,title: '实收(金额)' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                        }
                    }
                    ,{ field:'hang_money',visible : false, width: 90 ,title: '挂数(金额)' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                        }
                    },
                    { field:'registrar', width: 70 ,title: '记录员' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                        }
                    },{ field:'create_date', width: 170 ,visible : false, title: '创建日期' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                        }
                    },{ field:'last_name_cash',visible : false, width: 90 ,title: '最后修改人' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                        }
                    },{ field:'last_date_cash', visible : false,width: 120 ,title: '修改日期' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                        }
                    },{ field:'re_check_man', visible : false,width: 60 ,title: '复核' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                        }
                    },{ field:'re_check_man_date',visible : false, width: 120 ,title: '复核日期' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                        }
                    },
                    { field:'check_man',visible : false, width: 70 ,title: '审核员' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                        }
                    },{ field:'check_man_date',visible : false, width: 120 ,title: '审核日期' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                        }
                    },{ field:'is_cancel_name', width: 60 ,title: '状态' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                        }
                    },{ field:'remark_cash', visible : false, width: 80 ,title: '单据备注' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                        }
                    },
                ],
                tableDataMain : [],
                allFinanceCount : 0,
                filterData: {

                },
                sortData: {
                    'cash_date':'asc'
                },
                pickerMonthRange: [startDate, new Date()],
                pickerDate: [startDate, new Date()],
                activeName:'first',
           }
       },
        methods: {
            searchAllFinance(){
                this.tablePage.currentPage = 1;
                this.getAllFinance();
           },
            getAllFinance(){
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据查询中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                this.$axios({
                    method:'POST',
                    url:'/admin/allFinance/search',
                    data:{
                        currentPage: this.tablePage.currentPage,
                        pageSize:this.tablePage.pageSize,
                        sortData:this.sortData,
                        filterData:this.filterData,
                        pickerDate:this.pickerDate,
                        formData:this.formData,
                        isAccurate:false,
                        searchType : this.type,
                   }
               }).then((response) =>{          //这里使用了ES6的语法
                    loading.close();
                    //console.log(response)       //请求成功返回的数据
                    if(response.status === 200){
                        this.tableDataMain = response.data.page.list
                        this.allFinanceCount = response.data.allFinanceCount
                        this.tablePage.pageSize = response.data.page.pageSize
                        this.tablePage.total = response.data.page.totalRow
                        this.tablePage.currentPage = response.data.page.pageNumber
                   }
               }).catch((error) =>{
                    loading.close();
                    console.log(error)
               });
           },
            handlePageChange ({ currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.getAllFinance();
           },
            openReceipt(row){
                if (row.is_cancel){
                    //this.$router.push({ path: '/receipt', query: { id: row.cash_id}})
                    this.$_openType({ path: '/receipt', query: { id: row.cash_id}})
               }else{
                    //this.$router.push({ path: '/receipt', query: { cash_type_id : row.cash_type_id,type:row.type,year:row.year,month:row.month}})
                    this.$_openType({ path: '/receipt', query: { cash_type_id : row.cash_type_id,type:row.type,year:row.year,month:row.month}})
               }

           },

            getNumberDefaultZero(obj) {
                //console.log("getNumberDefaultZero : " + obj);
                if (obj == null || typeof (obj) == 'undefined' || isNaN(obj)) {
                    return Number(0)
               } else {
                    return Number(obj)
               }
           },
            sumNum(list, field) {
                let count = Number(0)
                list.forEach(item => {
                    count = this.$XEUtils.add(count,item[field]);
               })
                return count
           },
            footerMethod({columns, data}) {
                const sums = []
                columns.forEach((column, columnIndex) => {
                    if (columnIndex === 0) {
                        sums.push('合计')
                   } else {
                        if (column.property === 'money_cash_data' || column.property === 'money_borrow' || column.property === 'money_loan') {
                            sums.push(this.$XEUtils.commafy(this.sumNum(data, column.property), { digits: 2}))
                       } else {
                            sums.push('')
                       }
                   }
               })
                //this.autoComputed();
                // 返回一个二维数组的表尾合计
                return [sums]
           },
            formatDate (value,format) {
                return XEUtils.toDateString(value, format)
           },
            cellClassName ({ row, column}) {
                if (column.property === 're_check_man') {
                    if (row.re_check_man) {
                        return 'col-success'
                   }else{
                        return 'col-fail'
                   }
               }else if (column.property === 'check_man') {
                    if (row.check_man) {
                        return 'col-success'
                   }else{
                        return 'col-fail'
                   }
               }
           },
            cellClickEvent({row,rowIndex}){
                if (this.$refs.xGrid.isCheckedByCheckboxRow(row)){
                    this.$refs.xGrid.setCheckboxRow([this.tableDataMain[rowIndex]], false)
               }else{
                    this.$refs.xGrid.setCheckboxRow([this.tableDataMain[rowIndex]], true)
               }
           },
            //双击提交状态，直接提交当前商品ID，设置对应反选状态
            celldbClickEvent ({ row, column}) {
                if (column.property =='re_check_man'){
                    let str = '正进行“复核”'
                    let isSetMan = true;
                    if (!this.isBlankVue(row.re_check_man)){
                        isSetMan = false;//取消出仓审核
                        str = '取消“复核”'
                   }
                    str = str + '\n出纳单据-单号：'+row.cash_type_id +'\n单据类型：'+row.cash_type +',科目名称:'+row.subject_name_cash_data +',往来单位:'+row.company_name +'\n合计金额: ' + row.total_amount + '元'
                    this.$XModal.confirm(str).then(type => {
                        //const $table = this.$refs.xTable
                        let params = {
                            id:row.id,
                            isSetMan:isSetMan
                       };
                        this.$_cellDoubleClickEvent(type,'/admin/allFinance/reCheckMan',params).then(() => {
                            this.getAllFinance();
                       });
                   })
               }else if (column.property =='check_man'){
                    let str = '正进行“审核”'
                    let isSetMan = true;
                    if (!this.isBlankVue(row.check_man)){
                        isSetMan = false;//取消出仓审核
                        str = '取消“审核”'
                   }
                    str = str + '\n出纳单据-单号：'+row.cash_type_id +'\n单据类型：'+row.cash_type +',科目名称:'+row.subject_name_cash_data +',往来单位:'+row.company_name +'\n合计金额: ' + row.total_amount + '元'
                    this.$XModal.confirm(str).then(type => {
                        //const $table = this.$refs.xTable
                        let params = {
                            id:row.id,
                            isSetMan:isSetMan
                       };
                        this.$_cellDoubleClickEvent(type,'/admin/allFinance/checkMan',params).then(() => {
                            this.getAllFinance();
                       });
                   })
               }
                //console.log(row,column);
           },
            //获取不同用户的授权科目权限
            getRoleSubjectByAccountId(query){
                this.$axios({
                    method:'post',
                    url:'/admin/roleSubject/getRoleSubjectByAccountId',
                    data:{
                        query:query
                    }
                }).then((response) =>{         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if(response.status === 200){
                        this.roleSubjectList = response.data;
                    }
                }).catch((error) =>{
                    console.log(error)
                });
            }
       },
        created () {
            this.$nextTick(() => {
                this.type = this.$route.query.type;
                if (this.type == 'PZ'){
                    this.formData.isPZ = true
                    this.tableColumn = this.tableColumnPZ
                    this.sortData = {
                        'voucher_type_id':'asc',
                        'cash_type_id':'asc'
                    }
                }
                this.getRoleSubjectByAccountId();
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)
                this.getAllFinance();
           })
       }
   };


</script>

<style scoped>
    .header-wrapepr {display:flex; flex-direction:row;justcontent:space-between}
    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 130px;
   }
    .el-form-item__content .el-input{
        width: 130px;
   }

    /deep/ .el-input--suffix .el-input__inner{
        padding-right: initial!important;
   }

    /deep/ input[popperclass="myAutocomplete"]{
        padding:3px!important;
   }
    /deep/ .el-input__suffix {
        right: 0px!important;
   }

    /*修改行高*/
    /deep/ .vxe-table--render-default.size--mini .vxe-body--column.col--ellipsis, .vxe-table--render-default.size--mini .vxe-footer--column.col--ellipsis, .vxe-table--render-default.size--mini .vxe-header--column.col--ellipsis, .vxe-table--render-default.vxe-editable.size--mini .vxe-body--column{
        height: 24px !important;
   }
    /deep/ .vxe-table--render-default.size--mini .vxe-cell .vxe-default-input, .vxe-table--render-default.size--mini .vxe-cell .vxe-default-select, .vxe-table--render-default.size--mini .vxe-cell .vxe-default-textarea{
        height: 24px !important;
   }
    /deep/ .vxe-input--clear-icon.vxe-icon--close {
        height: 1.2em !important;
   }
    /deep/ .vxe-input.size--mini {
        height: 24px !important;
   }
    /deep/ table .el-input__inner {
        height: 22px !important;
        line-height: 24px !important;
   }
    /deep/ .vxe-table--render-default .vxe-body--column, .vxe-table--render-default .vxe-footer--column, .vxe-table--render-default .vxe-header--column {
        line-height: 20px !important;
   }
    /deep/ .vxe-button.size--mini.type--button.is--circle {
        min-width: 22px !important;
   }
    /deep/ .vxe-button.size--mini.type--button {
        height: 22px !important;
   }
    /deep/ .vxe-button.size--mini{
        font-size: 10px !important;
   }
    /*修改行高*/
</style>
